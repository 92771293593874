<template>
	<div class="main">
   
	<div class="main-container" >
    <div class="d-flex">
      <div class="content-wrapper" >
        <div class="row" v-if="profile">
         
                    <div class="column1">
                     
                       <div class="card">
                        <img v-if="profile.resultEntity.image == null" :src="require('../../../assets/profile.jpg')" alt="photo" style="width:100%">
                        <img v-else :src="image" style="width:100%"/>
                        <h5 class="text-uppercase">{{profile.firstName}} {{profile.middleName}} {{profile.lastName}}</h5>
                        <p class="title">{{profile.dob}}</p>
                        <p>{{profile.gender}}</p>
                        <p>
                          <button class="btn btn-green" v-if="profile.matchRate > 80" >{{ profile.matchRate }} %</button>
                          <button class="btn btn-red" v-if="profile.matchRate < 80" v-b-tooltip.hover title="Click to view more details">expand</button>
                          <button class="btn btn-secondary" @click="expand(profile)" v-b-tooltip.hover title="Click to view more details">expand</button>
                        </p>
                      </div>
                      <div class="card">
                        <button class="btn btn-secondary" @click="openMatchDecision(profile)" v-b-tooltip.hover title="Click to view and update match decision">Decision</button>
                      </div>
                    </div>
                    <div class="column2">
                      <div class="market-content">
                        <table class="table">
                              <tbody>
                                  <tr>
                                    <td>Category</td>
                                    <td>{{profile.category}}</td>
                                  </tr>
                                  <tr>
                                    <td>Subcategory</td>
                                    <td>{{profile.resultEntity.categories}}</td>
                                  </tr>
                                  <tr>
                                    <td>Matched Fields</td>
                                    <td>{{profile.matchedFields}}</td>
                                  </tr>
                                  <tr>
                                    <td>Last reviewed</td>
                                    <td>{{profile.resultEntity.lastReviewed}}</td>
                                  </tr>
                                  <tr>
                                    <td>Nationality</td>
                                    <td>
                                      <table>
                                        <tbody>
                                          <tr v-for="nationality in profile.resultEntity.nationalities" :key="nationality.id">
                                            <th>{{ nationality }}</th>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Location</td>
                                    <td>{{profile.primaryLocation}}</td>
                                  </tr>
                                  <tr>
                                    <td>Also Known As</td>
                                    <td>
                                      <table>
                                        <tbody>
                                          <tr v-for="name in profile.resultEntity.nameDetails" :key="name.id">
                                            <th>{{ name.firstName }} {{ name.middleName }} {{ name.lastName }}</th>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                </div> 
         </div>
      </div>
      
    </div>
    
    </div>
   
    <NamescreenResult :profile="profile" v-if="showExpand" @close="expand(profile)"/>
    <MatchDecision :profile="profile" v-if="showMatchDecision" @close="openMatchDecision(profile)"/>
	</div>
          
</template>
        
<script>
  import moment from 'moment';
  import NamescreenResult from '../../../components/modal/Compliance/NamescreenResult.vue'
  import MatchDecision from '../../../components/modal/Compliance/MatchDecision.vue'
 
  export default {
     props:["profile"],
      name: 'MatchCardModal',
      components: {
        NamescreenResult,
        MatchDecision
      },
      
       data: function() {
      return { 
       isLoading: false,
       showExpand: false,
       scanResults:'',
       image:'',
       scanId:'',
       showMatchDecision: false,

      
      }
      },
      computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
      
     
      },
      methods:{
        expand(profile){
          this.profile = profile
          this.isLoading = true
          this.showExpand = !this.showExpand
       
        },
        openMatchDecision(profile){
          this.profile = profile
          this.isLoading = true
          this.showMatchDecision = !this.showMatchDecision
        },
       
        getHistoryDetails(){   
          this.isLoading = true
          this.image = this.profile.resultEntity.image
          const options = {
            method: 'GET',
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'vmiadmin-authz': localStorage.getItem("tokenId") 
            }, 
            url: '/ax/namescan/details/' + this.$route.params.scanId
            }
            this.axios(options)
            .then((response) => {
            
              this.scanResults = response.data.scanResult
              console.log(this.scanResults.matchedEntities)
              this.isLoading = false
             
             
            }).catch((err) => {
                
            })
      },
       
       
       
          
      formatDate: function(date) {
        return moment(new Date(date)).format('MM/DD/YYYY hh:mm:ss A');
        },
      
          openSettings(){
          this.showSettings = !this.showSettings
        },
              
         
        
          sessionCheck() {
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
            
              url: '/ax/sessionCheck',
              }
            this.axios(options)
              .then((response) => {
             
              }).catch((err) => {
                  if(err.response.data.msgCode == '00001') {
                      window.location.href = "/#/login";
                  }  
              })
          },

      },
      mounted(){
          this.sessionCheck();
          this.getHistoryDetails();
      }
  }
</script>
        
<style scoped lang="scss">
    * {
    box-sizing: border-box;
  }
  
  /* Create two equal columns that floats next to each other */
  .column1 {
    float: left;
    width: 30%;
    padding: 10px;
  
  }
  .column2 {
    float: left;
    width: 70%;
    padding: 10px;
  
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  .btn-green{

  }
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column {
      width: 100%;
    }
  }
  .checkbox label{
    font-size: 12px !important;
  }
.header-wrapper img{
    width: 10em;
  }
  .main-content{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
    padding: 20px 5px;
    width: 100%;
  }
  .card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 300px;
  margin: auto;
  text-align: center;
  font-family: arial;
}
.card img{
  width: 10rem;
}
.title {
  color: grey;
  font-size: 18px;
}
.btn-green{
  background: green;
}
.btn-red{
  background: rgb(230, 100, 100);
}

.loading-spinner {
      border: 4px solid rgba(0, 0, 0, 0.3);
      border-top: 4px solid #007BFF; /* Change the color to your preferred color */
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 1s linear infinite;
      margin: 0 auto;
    }
    @keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
  .market-content {
      width: 100%;
      border-radius: 10px;
      padding: 0;
      table {
        width: 100%;
        thead {
          th {
            font-size: 15px;
            color: #c5c2c2; 
            letter-spacing: 0.65px;
            padding: 1em;
            position: sticky;
            top: 0;
            background-image: linear-gradient(
            to bottom,
            #5a5f71 29%,
            #10121C 88%
      );
            text-align: inherit;
          }
        }
  
        tbody {
          background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
      );
          td {
            color: #FFFFFF;
            letter-spacing: 0.65px;
            padding: 1em;
  
            a {
              color: #FFFFFF;
              text-decoration: none;
  
              &:hover {
                text-decoration: underline;
              }  
            }
  
            &.hash {
              a {
                color: #FFBE21;
              }
              
            }
          }
        }
      }
    }
  .form-control-short{
    border-radius: 10px;
    padding: 10px;
  }
  .content-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    justify-content: center !important;
    min-width: 350px !important;
    margin: 10px auto;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 5px;
    
  }
 
  .progress-bars {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .form-group .button{
    margin-top: 100px !important;
  }
  .icon{
    width: 2rem;
  }
  .progress-bar {
    width: 100px;
    height: 10px;
    background-color: lightgray;
    margin-right: 5px;
    border-radius: 6px;
  }
  .active {
    background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;;
  }
  .enhanced{
    margin-top: 2em;
  }

.three-column {
  display: flex;
  justify-content: space-between;
}
.modal-footer{
  border-top: none !important;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-column {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
}
form{
  padding-top: 3px;
}

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }
}
.btn .btn-checkbox{
  padding: 0px !important;
  margin: 0px !important;
}
.btn-checkbox .checkbox{
  width: 20px !important;
}
label {
  color: white;
  margin-right: 5px;
  font-size: 15px;
}
.modal-body{
  margin-left: 15px;
}

   .btn-flag {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    z-index: 2;
  }
   .mobile-container {
    position: relative;
   }
   .default-flag {
      width: 33px;
      margin-right: 0.25em;
    }
    

    .country-list {
      position: absolute;
      background: #ccc;
      width: 300px;
      color: #333;
      top: 70px;
      padding: 0.5em;
      height: 13em;
      overflow: auto;
      z-index: 3;
    }
      .row {
        margin: 0 !important;
      }
        .col-sm-6 {
          display: flex;
          padding: 0.5em;
          align-items: center;
          cursor: pointer;
        }
        .col-sm-6:hover {
            background: darken(#ccc, 10%);
          }
          .country-name {
            margin-left: 0.5em;
          }

          span {
            font-size: 0.9em;
          }
   

  .flag-list-img {
    width: 33px;
  }
  .btn-cancel{
        float: right;
        background-color: #333;
        color: white;
    }

  .disabled{
  cursor: not-allowed;
}
.input-group{
  flex-wrap: nowrap;
  padding: 0;
}
.input-group .form-control{
  height: auto;
}
fieldset{
  min-height: 50px !important;
}

.legend{
   color: white !important;
    padding-top: 10px;
  }
  h1 {
  text-align: center;
  font-size: 5rem;
  }

  form {
  width: 90%;
  margin: 0 auto;
  }

  .box {
  padding: 0.1rem;
  border-radius: 6px !important;
  margin-bottom: 10px;
  }

  label {
  display: flex;
  font-size: 16px;;
  }

  input, .form-control, .form-select {
  display: inline-block;
  width: 200px;
  border-radius: 10px;

  }

  input:required:invalid, input:focus:invalid {
  background-position: right top;
  background-repeat: no-repeat;
  -moz-box-shadow: none;
  }

  input:required:valid {
  background-position: right top;
  background-repeat: no-repeat;
  }

  #masthead {
    padding: 10rem 0;
    background-size: 100%;
    /*color: #fff;*/;
  }
  .help{
    margin: 2rem;
    
  }
@media all and (min-width: 35rem) and (max-width: 60rem) {
 
    .box {
    float: left;
    width: 33%;
    }
    .box2 {
    float: left;
    width: 50%;
    }

    label {
    display: flex;
    }

    #masthead {
    }
}

@media all and (min-width:60rem) {
  
    label {
    display: inline;
    width: 150px;
    float: left;
    }
    .box2 {
    float: left;
    width: 50%;
    }
    .box {
    width: 33%;
    float: left;
    }

    input {
    width: 50%;
    }

    #masthead {
    padding: 9rem;
    }
}




   

.icon img{
  width: 2rem;
}
.card{
  background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
  );
    box-shadow:0 0 30px rgba(0,0,0,0.3);
    border: 1px solid transparent;
    border-radius: 12px;
    width: 100%;
    height: auto;
    text-align: center;
    color: white;
}


.verified{
  background-color: green;
  color: white;
}
.submitted{
  background-color: green !important;
  color: white;
}
.notSubmitted{
  background-color: red;
  
}
  
 
  
    .progress-bars {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    
    /* margin-bottom: 20px; */
  }
  
  .progress-bar {
    width: 100px;
    height: 50px;
    background-color: lightgray;
    background-color: #ffffff !important;
    margin-right: 5px;
    border-radius: 20px !important;
    color: white !important;
    font-size: 10px;
    
  }
  
  .active {
    background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;;
    color: white !important;
  }

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }

  .progress-bar {
    width: 80px;
    height: 50px;
    background-color: lightgray;
    background-color: #ffffff !important;
    margin-right: 5px;
    border-radius: 20px !important;
    color: white !important;
    font-size: 10px;
    
    
  }
  
}
@media (max-width: 1280px) {

  
}
  
  label {
    margin-right: 5px;
  }
  

.btn-card-upload{
  width: 100%;
  height: auto;
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: gray !important;
  color: black;
  display: flex;
  justify-content: space-between;
 
}

.indicator img{
  width: 1rem;
}
.indicator-progress img{
  width: 1rem;
}

</style>
